.App {
  font-family: 'Montserrat', sans-serif;
  min-height: 100vh;
}

.transaction-message {
  color: #312067;
  text-align: center;
}

.creationsTab {
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 5px;
  margin-right: 10px;
  padding: 6px 12px;
  font-size: 0.85em;
  color: #676769;
  font-weight: 500;

  &.active {
    background-color: #FFF;
    color: #6212D7;
    border-bottom: #6212D7 2px solid;
    border-radius: 0px;
  }
}

.modalPromo {
  .modal-content {
    overflow: visible !important;

    .designImage {
      position: absolute;
    }

    #plantLeft {
      left: -100%;
      top: -50%;
      z-index: 1;
    }

    #plantRight {
      right: -100%;
      top: -50%;
      z-index: 1;
    }

    border: #000 3px solid;
    border-radius: 20px;
    background-color: transparent;
    overflow: hidden;
    background-color: #7BDDFF;
    padding: 15px;

    .festivalVideo {
      padding-bottom: 56%;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      #palapachaLogo {
        top: -80px;
        left: 30%;
        width: 40%;
      }

      #palapachaDates {
        bottom: -30px;
        left: 10%;
        width: 15%;
      }

      #palapachaLink {
        bottom: -50px;
        left: 38%;
        width: 25%;
      }

      #cloud1 {
        bottom: -30px;
        width: 20%;
        left: -10%;
      }

      #cloud2 {
        bottom: -50px;
        width: 30%;
        right: -25%;
      }

      #cloud3 {
        top: -80px;
        right: -15%;
        width: 15%;
      }

      #close {
        cursor: pointer;
        font-size: 2em;
        border-radius: 100%;
        padding: 0px 12px;
        border: 3px solid #CF0173;
        right: -20px;
        top: -20px;
        color: #CF0173;
        background-color: #FFF;
      }
    }
  }
}

@font-face {
  font-family: 'Bellania';
  src: local('Bellania'), url(./fonts/Bellania.ttf) format('truetype');
}

@font-face {
  font-family: 'ChineseRocks';
  src: local('ChineseRocks'), url(./fonts/Chinese_Rocks.ttf) format('truetype');
}

button:focus {
  outline: none;
}

.nibi-button {
  background-color: #290044;
  border: none;
  border-radius: 5px;
  color: #FFF;
  padding: 0.5rem 1.5rem;

  &:hover {
    color: #F2C600;
  }

  &:active {
    color: rgba(242, 198, 1, 0.6);
  }
}

.nibi-button-2 {
  background-color: #E0A800;
  border: none;
  border-radius: 5px;
  color: #290044;
  padding: 0.5rem 1.5rem;

  &:hover {
    color: #290044;
  }

  &:active {
    color: rgba($color: #290044, $alpha: 0.6);
  }
}

#chatButton {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25D366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

#raceButton {
  padding: 10px;
  position: fixed;
  line-height: 20px;
  width: 140px;
  height: 75px;
  top: 100px;
  left: 40px;
  background-color: #FF9924;
  color: #FFF;
  border-radius: 20px;
  text-align: center;
  font-size: 30px;
  box-shadow: 0px 0px 5px #999;
  z-index: 100;
}

.question-icon-form {
  color: #6C757D;
  margin-left: 5px;
}

.label-red {
  color: red;
}

#emergencyBanner {
  background-image: url('./img/BannerCovid.png');
  background-size: 100% auto;
  background-position-x: center;
  background-position-y: center;
}

#emergencyBanner2 {
  background-image: url('./img/BannerCovid2.png');
  background-size: 100% auto;
  background-position-x: center;
  background-position-y: center;
}

.hex-div {
  position: relative;
  display: inline-block;
  margin: 1rem 4.5rem;
  width: 125px;
  height: calc(125px * 1.732);
  border-radius: 1rem/.5rem;
  background: #FFF;
  transition: opacity .5s;
  -webkit-box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 7px 10px 0px rgba(0, 0, 0, 0.3);

  .content {
    padding: 10px 5px;
    text-align: center;
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;

    p {

      width: 100%;

      &.title {
        font-size: 2.2rem;
        font-weight: bold;
        color: #302464;
      }

      &.text {
        font-size: 1.1rem;
        text-align: center;
        height: 110px;
        margin: 0;
      }

    }
  }

  &:before,
  &:after {
    position: absolute;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
    content: '';
  }

  &:before {
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
  }

  &:after {
    -webkit-transform: rotate(-60deg);
    transform: rotate(-60deg);
  }
}


.hexagon-btn {
  position: relative;
  width: 196px;
  height: 40px;
  background-color: #FCC010;
  text-decoration: none;
  color: #232229;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  border: none;
  margin: 0 12px;

  &.light-color {
    background-color: #F2E8DD;
  }
}

.hexagon-btn:after,
.hexagon-btn:before {
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  width: 0px;
  background: transparent;
  border-top: 20px transparent solid;
  border-bottom: 20px transparent solid;
}

.hexagon-btn:before {
  left: -12px;
  border-right: 12px #FCC010 solid;
}

.hexagon-btn:after {
  right: -12px;
  border-left: 12px #FCC010 solid;
}

.hexagon-btn.light-color:before {
  left: -12px;
  border-right: 12px #F2E8DD solid;
}

.hexagon-btn.light-color:after {
  right: -12px;
  border-left: 12px #F2E8DD solid;
}

.homeBannerSmall {
  font-family: 'Barlow', sans-serif;
  width: 100%;
  overflow: hidden;
  margin: 0;
  height: 75vh;
  background-color: #FFF;
}

#emergencyBanner {
  background-image: url('./img/BannerCovid.png');
}

#emergencyBanner2 {
  background-image: url('./img/BannerCovid2.png');
}

.carnavalForm {
  background-color: #FFF;
  margin: 40px 25%;
  padding: 40px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}

#carnavalBanner {
  background-image: url('./img/carnaval.png');
}

#bannerFoundationInfo {
  background: url('./img/Banners/maximizamos.webp');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: auto 100%;
  background-position-x: center;
}

#bannerCommerceInfo {
  background: url('./img/Banners/Reunimos.webp');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: auto 100%;
  background-position-x: center;
}

#bannerBusinessInfo {
  background: url('./img/Banners/Ayudamos.webp');
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: auto 100%;
  background-position-x: center;
}

.buttonNoStyle {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.buttonNoStyle:focus {
  outline: none;
}

.primaryFont {
  font-family: 'Montserrat', sans-serif;
}

.secondaryFont {
  font-family: 'Poppins', sans-serif;
}

.highlight {
  color: #290044;
  font-weight: bold;
}

.white {
  color: #FFF;
}

.program-list-header {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 10px;

  .highlight {
    color: #6307AF;
    font-weight: 600;
  }
}

.program-list-description {
  color: #777777;
  font-size: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.homeBannerCaptionContainer {
  font-family: 'Barrow', sans-serif;
  position: absolute;
  top: 35%;
  text-align: center;
  color: #FFF;
  width: 100%;
  font-weight: bold;
}

.homeBannerEmergenciesCaptionContainer {
  position: absolute;
  top: 150px;
  text-align: left;
  color: #FFF;
  width: 100%;
  font-weight: bold;
}

.homeBannerCaption {
  font-family: 'Montserrat', sans-serif;
  font-size: 4.5vmin;
  letter-spacing: 1.5px;
  font-weight: 600;
  line-height: 45px;
  margin-bottom: 8px;
}

.homeBannerCaptionSubtitle {
  letter-spacing: 1px;
  font-size: 3vmin;
  font-weight: 400;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  width: 40%;
  margin-right: auto;
  margin-left: auto;
}

.iconTitle {
  color: rgb(133, 133, 133);
  font-size: 12px;
}

.iconContent {
  margin-top: 6px;
  font-weight: 600;
  font-size: 20px;

  small {
    font-size: 12px;
    font-weight: 600;
    margin-top: -5px;
  }
}

.iconCategory {
  margin-top: 6px;
  color: darkgray;
  font-size: 11px;
  font-weight: 600;
  height: 16px;
}

.iconSessions {
  height: 72px;
  border-left: solid #353D4C 1px;
  border-right: solid #353D4C 1px;
}

.cardImg {
  display: block;
  height: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;
}

.cardImgLong {
  display: block;
  width: 100%;
  min-height: 100%;
  background-size: cover;
  background-position: center;
  align-items: center;
}

.programFilterBar {
  border-style: solid;
  border-width: thin;
  border-color: #AFB9C1;
  border-radius: 5px;
  text-align: center;
  background-color: #FFF;
  margin: 20px 0px;
  padding: 10px 0px;
}

.programFilter {
  position: relative;
  width: 80%;
  color: #311F67;
  border-color: #311F67;

  select {
    display: none;
  }
}

.programCard {
  background-color: #FFF;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3), 0 0px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  transition: 0.3s all;
}

.programCardSmall {
  background-color: #FFF;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.3), 0 2px 5px 0 rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
}

.program-card-primary-title {
  font-size: 1.1em;
  margin-left: 10px;
  text-transform: uppercase;
  color: black;
  font-weight: 600;
}

.program-card-secondary-title {
  font-size: 15px;
  color: #777;
}

.programCard:hover {
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5), 0 0px 5px 0 rgba(0, 0, 0, 0.5);
}

.programCardImg {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.programCardImgExp {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  height: 220px;
  overflow: hidden;
}

.programCardContentExp {
  padding: 15px;
  height: 220px;
}

.programCardDescriptionExp {
  padding-top: 10px;
  font-size: 0.8em;
  color: #777;
  overflow: hidden;
  text-align: justify;
  height: 80px;
  margin-bottom: 5px;
}

.programCardDescription {
  padding-bottom: 10px;
  font-size: 15px;
  color: #777;
  font-weight: 300;
  height: 90px;
  overflow: hidden;
  text-align: justify;
}

.program-card-description-item {
  border-bottom: 1px solid #EEE;
  margin-bottom: 15px;
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
  margin-left: 15px;
  margin-right: 15px;
  width: 100%;
}

.program-card-description-item.description-date {
  color: rgb(176, 18, 197);
  font-size: 17px;
}

.program-card-description-item.description-location {
  color: rgb(197, 84, 18);
  font-size: 17px;
}

.description-location-value,
.description-date-value {
  float: right;
  font-size: 15px;
  color: #777;
  font-weight: 300;
}

.program-card-btn {
  background-color: #FFC107;
  border-color: #FFC107;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  color: #111;
  font-size: 15px;

  &:hover {
    background-color: #E0A800;
  }
}

.smallFont {
  font-size: 2.2vmin;
}

.paginationSpinner {
  text-align: center;
  margin: 20px;
}

/* For Loader*/
.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/*For page content*/
.centerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* The side navigation menu */
.cartPanel {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 100;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: #FFF;
  /* Black*/
  overflow-x: hidden;
  /* Disable horizontal scroll */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
}

.cartCloseBtn {
  font-size: 36px;
  cursor: pointer;
  margin-right: 15px;
}

.cartOverlay {
  position: fixed;
  /* Sit on top of the page content */
  display: none;
  /* Hidden by default */
  width: 100%;
  /* Full width (cover the whole page) */
  height: 100%;
  /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Black background with opacity */
  z-index: 99;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer;
  /* Add a pointer on hover */
}

/*Loader */
.loader {
  color: #312067;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }

  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }

  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }

  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }

  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*End of loader*/

@media (max-width: 768px) {

  .modalPromo {
    .modal-content {
      overflow: visible !important;

      .designImage {
        position: absolute;
      }

      #plantLeft {
        left: -100%;
        top: -100%;
        z-index: -1;
        height: 80vh;
      }

      #plantRight {
        right: -100%;
        top: -100%;
        z-index: -1;
        height: 80vh;
      }

      border: #000 3px solid;
      border-radius: 20px;
      background-color: transparent;
      overflow: hidden;
      background-color: #7BDDFF;
      padding: 15px;

      .festivalVideo {
        padding-bottom: 56%;

        iframe {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        #palapachaLogo {
          top: -80px;
          left: 30%;
          width: 40%;
        }

        #palapachaDates {
          bottom: -30px;
          left: 10%;
          width: 20%;
        }

        #palapachaLink {
          bottom: -30px;
          left: 35%;
          width: 30%;
        }

        #cloud1 {
          bottom: -30px;
          width: 20%;
          left: -10%;
        }

        #cloud2 {
          bottom: -50px;
          width: 30%;
          right: -25%;
        }

        #cloud3 {
          top: -80px;
          right: -15%;
          width: 15%;
        }

        #close {
          cursor: pointer;
          font-size: 1em;
          border-radius: 100%;
          padding: 0px 6px;
          border: 3px solid #CF0173;
          right: -20px;
          top: -20px;
          color: #CF0173;
          background-color: #FFF;
        }
      }
    }
  }

  #raceButton {
    top: 80px;
    left: 15px;
    width: 100px;
    height: 50px;
    font-size: 1.1em;
    padding: 3px;
  }

  #chatButton {
    width: 50px;
    height: 50px;
    font-size: 1.2em;
  }

  .homeBannerCaption {
    line-height: 30px;
  }

  .homeBannerCaptionContainer {
    left: 0;
    text-align: center;
  }

  .homeBannerCaptionSubtitle {
    width: 100%;
    font-size: 4.5vmin;
  }

  .home-banner-item-btn {
    width: 100%;
  }

  #carnavalBanner {
    background-image: url('./img/carnaval-mobile.png');
  }

  .carnavalForm {
    margin: 40px 5%;
    padding: 20px;
  }

  #emergencyBanner {
    background: url('./img/BannerCovidMobile.png');
  }

  #emergencyBanner2 {
    background-image: url('./img/BannerCovidMobile2.png');
  }
}

.logo-cemex-demo {
  position: absolute;
  width: 29% !important;
  top: 15px;
  right: 0px;
}

.nibi-btn {
  color: #FFF;
  background-color: #6212D7;
  border: none;
  border-radius: 15px;
  padding: 0.5rem 3rem;
  display: block;
  margin: 10px auto 20px;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba($color: #6212D7, $alpha: 0.5);
  }
&:disabled {
  background-color: #676769;
  cursor: not-allowed;
}
}

hr {
  border-top: solid 1px rgba(48, 36, 100, 0.6);
}

.nibi-label {
  color: #302464;
  font-weight: 500;
}
.text-invalid {
  color: red;
  font-weight: bold;
}