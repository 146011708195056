.payment-extension-component-fp {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 100px;

  .title {
    font-weight: 800;
    color: #1A5158;
    letter-spacing: 0.15em;
  }

  .amount {
    color: #D1BA98;
    font-weight: 400;
  }

  .plan-description {
    margin-bottom: 50px;

    .plan-title {
      font-weight: 700;
      color: #1A5158;
    }

    p {
      color: #1A5158;
      font-weight: 400;
    }
  }

  .sliderContainer{
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .selectedSlideContainer {
    height: fit-content;
    margin-bottom: 24px;
    text-align: center;
  }

  .carouselImage {
    margin-left: auto;
    margin-right: auto;
    height: 70px;
    max-width: 100%;
    text-align: center;
  }

  .selectedSlideImage {
    width: 100%;
  }

  .slick-prev:before,
  .slick-next:before {
    color: gray;
  }

}
