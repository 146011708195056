.nibi-home-foundations {
  font-family: 'Montserrat', sans-serif;

  .top-banner-foundations {
    overflow: hidden;
    position: relative;
    background-color: #B6A7E7;
    height: 70vh;
    margin-bottom: -100px;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url("../../img/FoundationLanding/foundations_banner.png");

    .top-banner-title {
      color: #34206f;
      font-weight: 800;
      font-size: 2.25em;
      padding: 0px 6%;
      padding-top: 70px;
      max-width: 50%;
      z-index: 2;
    }
    .top-banner-subtitle {
      color: #072450;
      font-weight: 700;
      font-size: 1.5em;
      padding: 0px 6%;
      padding-top: 5px;
      max-width: 50%;
    }
    img {
      position: absolute;
      &#nibiGlobe {
        height: 200px;
        top: 15%;
        left: 40%;
        animation: floatRight 300000ms linear 0s 1 forwards;
        animation-iteration-count: infinite;
        z-index: -1;
      }
      &#nibiBee1 {
        height: 160px;
        left: 80px;
        top: 40vh;
      }
      &#nibiBee2 {
        height: 100px;
        left: 350px;
        top: 42vh;
      }
      &#nibiBee3 {
        height: 100px;
        left: 450px;
        top: 44vh;
      }
      &#nibiBee4 {
        height: 80px;
        right: 160px;
        top: 35vh;
        z-index: 1;
      }
      &#nibiWind {
        height: 300px;
        right: 20vh;
        top: 12vh;
      }
      &#nibiCloud1 {
        height: 80px;
        right: 60px;
        top: 10vh;
        animation: floatCloudLeft 150000ms linear 0s 1 forwards;
        animation-iteration-count: infinite;
        z-index: -2;
      }
      &#nibiCloud2 {
        height: 90px;
        left: -20px;
        top: 25vh;
        animation: floatCloud 300000ms linear 0s 1 forwards;
        animation-iteration-count: infinite;
        z-index: -2;
      }
    }
  }
  .filter-title {
    margin-top: 20px;
    font-size: 0.75em;
    text-align: center;
    color: #7827EE;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: #7827EE 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .nibify-details {
    color: #072450;
    margin-top: 20px;
    font-size: 1.3em;
    .detail-title {
      font-size: 0.7em;
      font-weight: 600;
    }
    .detail-description {
      margin-bottom: 30px;
      font-size: 0.6em;
      font-weight: 500;
    }
  }
  .references-nibi {
    font-size: 0.75em;
    color: #302464;
    font-weight: bold;
    background-color: #F6F5F8;
    padding: 8px 20px;
    margin-top: 20px;
    min-height: 80px;
    border-radius: 5px;
    img {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      height: 22px;
      margin-bottom: 20px;
      &.awards-nibi {
        height: 55px;
      }
    }
  }
  .nibify-plan-banner {
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    margin-top: 20px;
    color: #7827EE;
    text-align: center;
    font-weight: 600;
    border-radius: 20px;
    min-height: 300px;
    font-size: 0.85em;
    .nibify-plan-banner-text {
      color: #34206F;
      font-size: 0.85em;
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
      border-radius: 30px;
      padding: 4px 12px;
      background-color: #FFFFFF3A;
      backdrop-filter: blur(5px);
    }
  }

  .nibify-steps {
    padding: 0px 15px;
    margin-top: 20px;

    .steps-title {
      color: #34206F;
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 1.2rem;
      text-align: center;
    }

    .steps-description {
      font-size: 0.75em;
      color: #072450;
      font-weight: 500;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;

        img {
          height: 28px;
        }
      }

      .step-number {
        border: #7827EE 1px solid;
        color: #7827EE;
        font-weight: 600;
        font-size: 1.2em;
        margin-right: 20px;
        border-radius: 50%;
        padding: 2px 8px;
        height: 27px;
        width: 27px;
        text-align: center;
      }
    }
    .select-plan-title {
      text-align: center;
      margin-top: 20px;
      color: #7827EE;
      border-bottom: #7827EE solid 1px;
      padding-bottom: 10px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.75em;
      margin-bottom: 30px;
    }
    .plan-card {
      min-height: 120px;
      padding: 10px 30px;
      margin-bottom: 30px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.3);
      .plan-title {
        font-size: 1.1em;
        color: #34206f;
        font-weight: bold;
      }
      .plan-description {
        max-width: 50%;
        font-size: 0.75em;
        color: #072450;
        font-weight: 500;
      }
      .plan-cost {
        color: #34206f;
        font-weight: bold;
        font-size: 0.95em;
      }
      button {
        position: absolute;
        right: 30px;
        bottom: 40px;
        color: white;
        background-color: #282a31;
        border: #7827EE 1px solid;
        border-radius: 30px;
        font-weight: 600;
        font-size: 0.8em;
        padding: 8px 20px;
      }
    }
  }
  .products-section {
    margin-bottom: 30px;
    .products-section-title {
      margin-top: 30px;
      color: #302464;
      text-align: center;
      font-weight: bold;
      font-size: 1em;
      padding: 0px 40px;
      margin-bottom: 30px;
    }
    .product-section-card {
      .product-card-img {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        width: 240px;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .commerceImg{
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        top: 20px;
        left: 15px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  .allies-section {
    margin-top: 50px;

    .allies-section-title {
      color: #302464;
      text-align: center;
      font-weight: bold;
      font-size: 0.85em;
      padding: 0px 40px;
      margin-bottom: 20px;
    }

    .allies-section-card {
      text-align: center;
      border-radius: 30px;
      margin-bottom: 30px;
      min-height: 250px;
      box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.2);
      padding: 15px 30px;

      img {
        max-height: 100px;
        max-width: 100%;
        margin: 10px;
      }
    }
  }

  .register-section{
    background-color: #7F69D5;
    padding: 60px;
    margin-top: 80px;
    .register-title{
      color: #072450;
      font-weight: 800;
      margin-bottom: 10px;
      font-size: 1.3em;
    }
    .register-description{
      color: #072450;
      font-weight: 600;
      margin-bottom: 20px;
      max-width: 600px;
    }
    .register-button{
      padding: 15px 60px;
      width: fit-content;
      text-align: center;
      background-color: white;
      color: #7827EE;
      font-weight: 600;
      font-size: 1.1em;
      border-radius: 8px;
      margin-bottom: 50px;
      box-shadow: 1px 8px 10px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
  }

  @keyframes floatRight {
    100% {
      transform: translateX(60vw);
      -webkit-transform: translateX(60vw);
    }
    0% {
      transform: translateX(-135px);
      -webkit-transform: translateX(-135px);
    }
  }

  @-webkit-keyframes floatRight {
    100% {
      transform: translateX(60vw);
      -webkit-transform: translateX(60vw);
    }
    0% {
      transform: translateX(-135px);
      -webkit-transform: translateX(-135px);
    }
  }

  @keyframes floatCloud {
    100% {
      transform: translateX(105vw);
      -webkit-transform: translateX(105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }

  @-webkit-keyframes floatCloud {
    100% {
      transform: translateX(105vw);
      -webkit-transform: translateX(105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }

  @keyframes floatCloudLeft {
    100% {
      transform: translateX(-105vw);
      -webkit-transform: translateX(-105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }

  @-webkit-keyframes floatCloudLeft {
    100% {
      transform: translateX(-105vw);
      -webkit-transform: translateX(-105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }
}
.nibi-home {
  font-family: 'Montserrat', sans-serif;

  .top-banner {
    overflow: hidden;
    position: relative;
    background-color: #B6A7E7;
    height: 70vh;
    margin-bottom: -100px;
    z-index: -1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../../img/Home/Fondo_solo_web.webp');

    .top-banner-title {
      color: #34206f;
      font-weight: 800;
      font-size: 2.25em;
      padding: 0px 6%;
      padding-top: 70px;
      max-width: 50%;
      z-index: 2;
    }
    .top-banner-subtitle {
      color: #072450;
      font-weight: 700;
      font-size: 1.5em;
      padding: 0px 6%;
      padding-top: 5px;
      max-width: 50%;
    }
    img {
      position: absolute;
      &#nibiGlobe {
        height: 200px;
        top: 15%;
        left: 40%;
        animation: floatRight 300000ms linear 0s 1 forwards;
        animation-iteration-count: infinite;
        z-index: -1;
      }
      &#nibiBee1 {
        height: 160px;
        left: 80px;
        top: 40vh;
      }
      &#nibiBee2 {
        height: 100px;
        left: 350px;
        top: 42vh;
      }
      &#nibiBee3 {
        height: 100px;
        left: 450px;
        top: 44vh;
      }
      &#nibiBee4 {
        height: 80px;
        right: 160px;
        top: 35vh;
        z-index: 1;
      }
      &#nibiWind {
        height: 300px;
        right: 20vh;
        top: 12vh;
      }
      &#nibiCloud1 {
        height: 80px;
        right: 60px;
        top: 10vh;
        animation: floatCloudLeft 150000ms linear 0s 1 forwards;
        animation-iteration-count: infinite;
        z-index: -2;
      }
      &#nibiCloud2 {
        height: 90px;
        left: -20px;
        top: 25vh;
        animation: floatCloud 300000ms linear 0s 1 forwards;
        animation-iteration-count: infinite;
        z-index: -2;
      }
    }
  }
  .filter-title {
    margin-top: 20px;
    font-size: 0.75em;
    text-align: center;
    color: #7827EE;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: #7827EE 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .nibify-details {
    color: #072450;
    margin-top: 20px;
    font-size: 1.3em;
    .detail-title {
      font-size: 0.7em;
      font-weight: 600;
    }
    .detail-description {
      margin-bottom: 30px;
      font-size: 0.6em;
      font-weight: 500;
    }
  }
  .references-nibi {
    font-size: 0.75em;
    color: #302464;
    font-weight: bold;
    background-color: #F6F5F8;
    padding: 8px 20px;
    margin-top: 20px;
    min-height: 80px;
    border-radius: 5px;
    img {
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      height: 22px;
      margin-bottom: 20px;
      &.awards-nibi {
        height: 55px;
      }
    }
  }
  .nibify-plan-banner {
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 50px;
    margin-top: 20px;
    color: #7827EE;
    text-align: center;
    font-weight: 600;
    border-radius: 20px;
    min-height: 300px;
    font-size: 0.85em;
    .nibify-plan-banner-text {
      color: #34206F;
      font-size: 0.85em;
      margin-left: auto;
      margin-right: auto;
      max-width: 350px;
      border-radius: 30px;
      padding: 4px 12px;
      background-color: #FFFFFF3A;
      backdrop-filter: blur(5px);
    }
  }

  .nibify-steps {
    padding: 0px 15px;
    margin-top: 20px;

    .steps-title {
      color: #34206F;
      font-weight: 600;
      margin-bottom: 20px;
      font-size: 1.2rem;
      text-align: center;
    }

    .steps-description {
      font-size: 0.75em;
      color: #072450;
      font-weight: 500;
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      span {
        margin-right: 10px;

        img {
          height: 28px;
        }
      }

      .step-number {
        border: #7827EE 1px solid;
        color: #7827EE;
        font-weight: 600;
        font-size: 1.2em;
        margin-right: 20px;
        border-radius: 50%;
        padding: 2px 8px;
        height: 27px;
        width: 27px;
        text-align: center;
      }
    }
    .select-plan-title {
      text-align: center;
      margin-top: 20px;
      color: #7827EE;
      border-bottom: #7827EE solid 1px;
      padding-bottom: 10px;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 0.75em;
      margin-bottom: 30px;
    }
    .plan-card {
      min-height: 120px;
      padding: 10px 30px;
      margin-bottom: 30px;
      border-radius: 10px;
      box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.3);
      .plan-title {
        font-size: 1.1em;
        color: #34206f;
        font-weight: bold;
      }
      .plan-description {
        max-width: 50%;
        font-size: 0.75em;
        color: #072450;
        font-weight: 500;
      }
      .plan-cost {
        color: #34206f;
        font-weight: bold;
        font-size: 0.95em;
      }
      button {
        position: absolute;
        right: 30px;
        bottom: 40px;
        color: white;
        background-color: #282a31;
        border: #7827EE 1px solid;
        border-radius: 30px;
        font-weight: 600;
        font-size: 0.8em;
        padding: 8px 20px;
      }
    }
  }
  .products-section {
    margin-bottom: 30px;
    .products-section-title {
      margin-top: 30px;
      color: #302464;
      text-align: center;
      font-weight: bold;
      font-size: 1em;
      padding: 0px 40px;
      margin-bottom: 30px;
    }
    .product-section-card {
      .product-card-img {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        width: 240px;
        height: 300px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .commerceImg{
        position: absolute;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        top: 20px;
        left: 15px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  .allies-section {
    // margin-top: 50px;
    padding-top: 50px;
    .allies-section-title {
      color: #302464;
      text-align: center;
      font-weight: bold;
      font-size: 0.85em;
      padding: 0px 40px;
      margin-bottom: 20px;
    }

    .allies-section-card {
      text-align: center;
      border-radius: 30px;
      margin-bottom: 30px;
      min-height: 250px;
      box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.2);
      padding: 15px 30px;

      img {
        max-height: 100px;
        max-width: 100%;
        margin: 10px;
      }
    }
  }

  .register-section{
    background-color: #7F69D5;
    padding: 60px;
    margin-top: 80px;
    .register-title{
      color: #072450;
      font-weight: 800;
      margin-bottom: 10px;
      font-size: 1.3em;
    }
    .register-description{
      color: #072450;
      font-weight: 600;
      margin-bottom: 20px;
      max-width: 600px;
    }
    .register-button{
      padding: 15px 60px;
      width: fit-content;
      text-align: center;
      background-color: white;
      color: #7827EE;
      font-weight: 600;
      font-size: 1.1em;
      border-radius: 8px;
      margin-bottom: 50px;
      box-shadow: 1px 8px 10px 1px rgba(0, 0, 0, 0.3);
      cursor: pointer;
    }
  }

  @keyframes floatRight {
    100% {
      transform: translateX(60vw);
      -webkit-transform: translateX(60vw);
    }
    0% {
      transform: translateX(-135px);
      -webkit-transform: translateX(-135px);
    }
  }

  @-webkit-keyframes floatRight {
    100% {
      transform: translateX(60vw);
      -webkit-transform: translateX(60vw);
    }
    0% {
      transform: translateX(-135px);
      -webkit-transform: translateX(-135px);
    }
  }

  @keyframes floatCloud {
    100% {
      transform: translateX(105vw);
      -webkit-transform: translateX(105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }

  @-webkit-keyframes floatCloud {
    100% {
      transform: translateX(105vw);
      -webkit-transform: translateX(105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }

  @keyframes floatCloudLeft {
    100% {
      transform: translateX(-105vw);
      -webkit-transform: translateX(-105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }

  @-webkit-keyframes floatCloudLeft {
    100% {
      transform: translateX(-105vw);
      -webkit-transform: translateX(-105vw);
    }
    0% {
      transform: translateX(0px);
      -webkit-transform: translateX(0px);
    }
  }
}

.nibi-results{
  margin-top: 60px;
  .goBack{
    color: #34206F;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;
    i{
      margin-right: 10px;
    }
  }
  .nibi-results-title{
    margin-top: 20px;
    font-size: 0.75em;
    text-align: center;
    color: #7827EE;
    font-weight: bold;
    text-transform: uppercase;
    border-bottom: #7827EE 1px solid;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  .showMoreOptions{
    color: #34206F;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    margin: 30px;
    i{
      margin-right: 10px;
    }
  }
}

@media (max-width: 768px) {
  .nibi-home {
    .top-banner {
      background-image: url('../../img/Home/Fondo_solo.png');
      .top-banner-title {
        color: #34206f;
        font-weight: 800;
        font-size: 1.25em;
        padding: 0px 6%;
        padding-top: 70px;
        max-width: 75%;
        z-index: 2;
      }
      .top-banner-subtitle {
        color: #072450;
        font-weight: 700;
        font-size: 0.85em;
        padding: 0px 6%;
        padding-top: 5px;
        max-width: 90%;
      }
      img {
        position: absolute;
        &#nibiGlobe {
          height: 120px;
          top: 34%;
          left: 25%;
          animation: floatRight 100000ms linear 0s 1 forwards;
          animation-iteration-count: infinite;
          z-index: -1;
        }
        &#nibiBee1 {
          height: 90px;
          left: -20px;
          top: 45vh;
        }
        &#nibiBee2 {
          height: 60px;
          left: 90px;
          top: 47vh;
        }
        &#nibiBee3 {
          height: 60px;
          left: 160px;
          top: 47vh;
        }
        &#nibiBee4 {
          height: 60px;
          right: 30px;
          top: 45vh;
          z-index: 1;
        }
        &#nibiWind {
          height: 160px;
          right: 60px;
          top: 32vh;
        }
        &#nibiCloud1 {
          height: 55px;
          right: 60px;
          top: 10vh;
          animation: floatCloudLeft 150000ms linear 0s 1 forwards;
          animation-iteration-count: infinite;
          z-index: -2;
        }
        &#nibiCloud2 {
          height: 50px;
          left: -20px;
          top: 32vh;
          animation: floatCloud 300000ms linear 0s 1 forwards;
          animation-iteration-count: infinite;
          z-index: -2;
        }
      }
    }
    .nibify-details {
      color: #072450;
      margin-top: 20px;
      .detail-title {
        font-size: 0.7em;
        font-weight: 600;
      }
      .detail-description {
        margin-bottom: 30px;
        font-size: 0.6em;
        font-weight: 500;
      }
    }
  }
}
