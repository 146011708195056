.nibify-component {
  margin: 50px 0;

  .nibify-nav {

    margin-bottom: 40px;

    .nibify-progress-container {
      border-radius: 20px;
      border: #B7B7B7 1px solid;
      height: 15px;
  
      .progress-section {
        margin-top: -1px;
        height: 15px;
        border-radius: 20px;
        background-color: #FCC010;
      }
    }

    .nibify-nav-label {
      margin-top: 6px;
      font-size: 9pt;
      text-align: center;
      color: #B9B9B9;

      &.active {
        color: #34206F;
      }

    }
  }

  .nibify-title {
    font-family: 'Poppins', sans-serif;
    text-transform: uppercase;
    color: #7827EE;
    border-bottom: #7827EE 1px solid;
    text-align: center;
    padding-bottom: 15px;
    font-weight: bold;
    font-size: 1.15rem;
  }

  .plan-info {
    background: linear-gradient(118deg, #FFCD43, #E97D01);
    filter: drop-shadow(5pt 12pt 19px #825400);
    padding: 16px;
    border-radius: 15px;
    margin-bottom: 40px;

    .plan-title {
      color: #7827EE;
      font-weight: bold;
      font-size: 1.25rem;
      font-family: 'Poppins', sans-serif;
      border-bottom: 1px solid #7827EE;
      margin-bottom: 15px;

      .plan-price {
        float: right;
      }
    }

    .plan-description {
      color: #FFF;
      font-size: 0.9rem;
      font-family: 'Montserrat', sans-serif;
      margin-bottom: 10px;

      h5 {
        font-size: 1rem;
      }
    }

    .change-plan {
      display: flex;
      color: #FFF;
      justify-content: space-between;

      span { 
        text-decoration: underline;
        cursor: pointer;
      }

      .plan-price {
        font-family: 'Poppins', sans-serif;
        color: #7827EE;
        font-size: 1.3rem;
        font-weight: bold;
        text-decoration-line: none;
        cursor: default;
      }

    }
  }

  .showMoreOptions {
    color: #34206F;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    margin: 30px;

    i {
      margin-right: 10px;
    }

  }

  .goBack {
    color: #34206F;
    font-weight: bold;
    cursor: pointer;
    width: fit-content;

    i {
      margin-right: 10px;
    }

  }

}

.nibify-change-plan-modal {
  .plan-card {
    min-height: 160px;
    padding: 10px 30px;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0px 6px 12px 1px rgba(0, 0, 0, 0.3);
    .plan-title {
      font-size: 1.1em;
      color: #34206f;
      font-weight: bold;
    }
    .plan-description {
      max-width: 50%;
      font-size: 0.75em;
      color: #072450;
      font-weight: 500;
    }
    .plan-cost {
      color: #34206f;
      font-weight: bold;
      font-size: 0.95em;
    }
    button {
      position: absolute;
      right: 30px;
      bottom: 40px;
      color: white;
      background-color: #282a31;
      border: #7827ee 1px solid;
      border-radius: 30px;
      font-weight: 600;
      font-size: 0.8em;
      padding: 8px 20px;
    }
  }
}
