.landing-section {
  margin-top: 50px;

  h1 {
    font-size: 1.4rem;
    color: #382564;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px;
  }

  p {
    color: #382564;
    text-align: center;
  }
}

.plan-toggle-buttons {
  text-align: center;
  margin-bottom: 50px;

  button {
    background-color: #fff;
    border: 1px solid #7827ee;
    color: #382564;
    padding: 5px 40px;
    border-radius: 20px;
    // z-index: -1;
    position: relative;

    &.active {
      z-index: 1;
      background-color: #7827ee;
      color: #fff;
    }
  }
}

.bubble {
  position: relative;
  width: 150px !important;
  background: #FCC010 !important;
  border-radius: 40px;
  padding: 10px 15px;
  text-align: center;
  color: #382564;
  font-size: 0.8rem;
  position: absolute;
  top: -50px;
  left: 165px;
}

.bubble-bottom-left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 16px solid #FCC010 !important;
  border-right: 8px solid transparent;
  border-top: 8px solid transparent !important;
  border-bottom: 10px solid transparent;
  left: 15px;
  bottom: -10px !important;
  background: transparent;
}

.plan-desc {
  box-shadow: 2px 4px 30px #0000003b;
  border-radius: 15px;

  .plan-title {
    color: #282a31;
    text-align: center;
    font-weight: bold;
    padding-top: 25px;
  }

  .plan-item {
    display: flex;
    color: #382564;
    font-size: 0.85rem;
    padding: 0 15px;
    align-items: center;

    &.plan-fees {
      font-size: 0.75rem;
      padding: 0 7px;
    }

    &.plan-price {
      height: 70px;
      padding: 0 5px;

      .price {
        font-size: 1.6rem;
        font-weight: bold;

        span {
          font-size: 1rem;
        }
      }
    }

    &.plan-item-icon {
      justify-content: center;
      font-size: 1.5rem;
    }
  }

  &.plan-features {
    .plan-item {
      justify-content: center;
    }
  }

  &.plan-free {
    .excluded {
      i {
        color: #282a31 !important;
      }
    }

    .included {
      i {
        color: #34a853 !important;
      }
    }
  }

  &.plan-basic {
    border: 3px solid #ffeec1;

    .excluded {
      i {
        color: #282a31 !important;
      }
    }

    .included {
      i {
        color: #34a853 !important;
      }
    }
  }

  &.plan-advanced {
    border: 3px solid #efc341;

    .excluded {
      i {
        color: #282a31 !important;
      }
    }

    .included {
      i {
        color: #34a853 !important;
      }
    }
  }

  &.plan-pro {
    border: 3px solid #ffeec1;

    .excluded {
      i {
        color: #282a31 !important;
      }
    }

    .included {
      i {
        color: #34a853 !important;
      }
    }
  }
}

i.plan-item-question-icon {
  margin-left: 5px;
  color: #dadada;
}

.popular-plan {
  background-color: #efc341 !important;
  border-radius: 30px;
  color: #fff;
  padding: 4px 15px;
  width: fit-content;
  margin: -42px auto 10px;
}

.landing-button {
  background-color: #6212d7;
  border: none;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px #00000029;

  &.active {
    text-decoration: underline;
    color: #6212d7;
    background-color: #fff;
    border: 1px solid #6212d7;
  }
}

.current-plan {
  .plan-title {
    font-weight: bold;
  }

  .plan-sub-title {
    border-bottom: 2px #e0e0e0 solid;
    padding-bottom: 3px;
  }

  .plan-btns {
    display: flex;
    justify-content: space-between;

    button {
      padding: 10px 15px;
      font-size: 1rem;
    }
  }
}

.nibi-btn {
  padding: 10px 15px;
  font-size: 0.9rem;
  display: block;
  margin: 15px auto 0;

  &.inactive {
    background-color: #fff;
    border: 1px solid #6212d7;
    color: #6212d7;
  }
}

.plan-card-title {
  font-size: 1.5rem;
}

.plan-card-items {
  margin-top: 20px;

  .plan-card-item {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
}