.landing-pse-payment-component, .landing-pse-spinner {
  margin-top: 150px;
  margin-bottom: 100px;
}

.landing-pse-spinner {
  
  .message {
    color: #312067;
    text-align: center;
  }
}

.landing-pse-payment-component {
  color: #312067;
  text-align: center;

  .title {
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 40px;
  }

  a, a:hover {
    text-decoration: none;
  }

  .btn.btn-modal {
    width: fit-content;
    font-size: 1.2rem;
  }
}
