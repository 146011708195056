.nequi-payment-component {

  .transaction-message {
    color: #312067;
    text-align: center;

    &.transaction-status-message {
      margin-top: 150px;
    }
  }
}