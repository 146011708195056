html {
  scroll-behavior: smooth;
}

.nibifyFilter {
  margin-bottom: 50px;

  .filterCard {
    text-align: center;
    padding: 10px 12px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #F6F5F8;
    color: #34206F;
    font-weight: bold;
    font-size: 0.75em;
    user-select: none;

    img {
      margin-right: 10px;
      height: 20px;
    }

    &.active {
      background-color: #FFB400;
    }
  }

  .filterStepLimit {
    color: #A8A8A8;
    text-align: center;
    font-size: 0.75em;
    margin-bottom: 15px;
  }

  .filterCardSmall {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    text-align: center;
    font-size: 0.75em;
    font-weight: 600;
    color: #34206F;
    margin-bottom: 5px;
    padding: 1px 15px;
    cursor: pointer;
    user-select: none;

    &.active{
      background-color: #7827EE;
      color: #FFF;
      border-radius: 12px;
      font-weight: 400;
    }

    .removeFilter {
      margin-left: 10px;
    }
  }

  .filterStep {
    border-radius: 4px;
    box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    padding: 15px 20px;
    background-color: #FFF;

    hr {
      margin-top: 10px;
    }

    .filterStepTitle {
      text-align: center;
      color: #34206F;
      font-weight: 700;
      font-size: 0.75em;
      cursor: pointer;

      i {
        color: #9A9A9A;
        float: right;
      }

      .filterStepTitleImg {
        margin-left: -20px;
        margin-right: 20px;
      }
    }
    .continueBtn {
      color: #7827EE;
      border: 1px solid #7827EE;
      border-radius: 7px;
      background-color: #FFF;
      font-size: 0.75em;
      font-weight: 700;
      padding: 10px 50px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;

      &:disabled {
        background-color: #ECECEC;
      }
    }

    &.search-foundation-section {
      display: flex;

      .foundation-search-input {
        flex: 1;
        margin-right: 5px;
      }

      .continueBtn {
        height: calc(1.5rem + 0.75rem + 2px);
        margin: 0;
      }
    }
  }

  .advancedSearch {
    cursor: pointer;
    color: #072450;
    font-size: 0.85em;
    font-weight: 500;
    text-align: right;

    i {
      margin-right: 5px;
    }
  }
}

.advancedSearchModal {
  color: #7827EE;
  font-weight: 600;
  font-size: 0.9em;
  padding: 20px 30px;

  .closeBtn {
    position: absolute;
    cursor: pointer;
    right: 40px;
    top: 25px;
    color: #34206F;
  }

  .advancedSearchTitle {
    text-align: center;
  }

  .advancedSearchFilter {
    margin-top: 30px;
  }

  hr {
    margin-top: 2px;
  }

  .filterCard {
    text-align: center;
    padding: 10px 12px;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #F6F5F8;
    color: #34206F;
    font-weight: bold;
    font-size: 0.85em;
    user-select: none;

    &.active {
      background-color: #FFB400;
    }
  }

  .filterCardSmall {
    margin-left: 15px;
    width: fit-content;
    text-align: left;
    font-size: 0.95em;
    font-weight: 600;
    color: #34206F;
    margin-bottom: 5px;
    padding: 1px 15px;
    cursor: pointer;
    user-select: none;

    &.active {
      background-color: #7827EE;
      color: #FFF;
      border-radius: 12px;
      font-weight: 400;
    }

    .removeFilter {
      margin-left: 10px;
    }
  }
  .continueBtn {
    color: #7827EE;
    border: 1px solid #7827EE;
    border-radius: 7px;
    background-color: #FFF;
    font-size: 0.75em;
    font-weight: 700;
    padding: 10px 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
}

@media (max-width: 768px) {
  .nibifyFilter {

    margin-bottom: 50px;

    .filterCard {
      padding: 5px 6px;
    }

    .filterStep {
      padding: 10px 15px;

      &.search-foundation-section {
        display: block;

        .continueBtn {
          margin: 0 auto;
          display: block;
        }

        .foundation-search-input {
          margin-right: 0;
        }
      }
    }

  }
}
