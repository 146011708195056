.upgrade-billing-plan-modal-component {

  padding: 15px 0;

  .warning-message-text {
    text-align: center;
  }

  .upgrade-billing-plan-btns {
    display: flex;
    justify-content: center;
  }

  .upgrade-billing-plan-btn {
    padding: 5px 30px;
    border: 2px solid #6212D7;
    color: #6212D7;
    border-radius: 42px;
    background-color: #FFF;
    font-weight: 500;
    display: block;
    margin: 20px 5px 0;
  }
}