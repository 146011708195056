.payment-extension-component {
  i {
    font-size: 1.5rem;
    margin-bottom: 15px;
    color: #6212D7;

    &:hover {
      cursor: pointer;
    }
  }
}
