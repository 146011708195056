.foundations-landing {
  margin-top: 25px;
  margin-bottom: 100px;

  .landing-link {
    color: #007bff;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  i.plan-item-question-icon {
    margin-left: 5px;
    color: #dadada;
  }

  .popular-plan {
    background-color: #fcc010;
    border-radius: 30px;
    color: #fff;
    padding: 4px 15px;
    width: fit-content;
    margin: -42px auto 10px;
  }

  .bubble {
    position: relative;
    width: fit-content;
    background: #fff;
    border-radius: 40px;
    padding: 10px 15px;
    text-align: center;
    color: #382564;
    font-size: 0.8rem;
    position: absolute;
    top: -50px;
    left: 165px;
  }

  .bubble-bottom-left:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 16px solid #fff;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
    border-bottom: 10px solid transparent;
    left: 15px;
    bottom: -15px;
  }

  .plan-toggle-buttons {
    text-align: center;
    margin-bottom: 50px;

    button {
      background-color: #fff;
      border: 1px solid #7827ee;
      color: #382564;
      padding: 5px 40px;
      border-radius: 20px;
      // z-index: -1;
      position: relative;

      &.active {
        z-index: 1;
        background-color: #7827ee;
        color: #fff;
      }
    }
  }

  .webinar-img {
    width: 100%;
    max-width: 380px;
    display: block;
    margin: 25px auto;
  }

  .banner {
    height: 50vh;
    background: url("../../img/FoundationLanding/banner-2.png");
    background-size: cover;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0px 5px 5px #00000029;
  }

  .video-cover {
    background: url('../../img/FoundationLanding/video-img.jpeg');
    max-width: 560px;
    width: 100%;
    height: 260px;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    i {
      font-size: 4rem;
      color: #7827ee;
    }

    &:hover {
      i {
        font-size: 4.35rem;
      }
    }
  }

  .landing-button {
    background-color: #6212d7;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px #00000029;

    &.active {
      text-decoration: underline;
      color: #6212d7;
      background-color: #fff;
      border: 1px solid #6212d7;
    }
  }

  .landing-button-2 {
    background-color: #282a31;
    color: #fff;
    border: #7827ee 1px solid;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    border: none;
    color: #fff;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px #00000029;

    a {
      &:hover {
        text-decoration: none;
      }
    }
  }

  .landing-section {
    margin-top: 50px;

    h1 {
      font-size: 1.4rem;
      color: #382564;
      font-weight: bold;
      text-align: center;
      margin-bottom: 25px;
    }

    p {
      color: #382564;
      text-align: center;
    }
  }

  .tab-section {
    text-align: center;

    button {
      margin-bottom: 10px;
    }
  }

  .desc-section-1 {
    h3 {
      font-weight: bold;
    }

    background-color: #f5f5f5;
    margin-top: 50px;
    padding: 100px 0;
    color: #382564;
  }

  .foundation-landing-title {
    h3 {
      font-weight: bold;
    }

    text-align: center;
    color: #382564;
  }

  .desc-info {
    width: fit-content;
    margin: 0 auto;

    .desc-items {
      div {
        margin-top: 10px;
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
          border-radius: 100%;
          height: 15px;
          width: 15px;
          display: inline-block;
          margin-right: 15px;
        }
      }
    }

    button {
      margin-top: 15px;
    }
  }

  .plans-section {
    background-color: #f5f5f5;
    padding-bottom: 50px;
  }

  .plan-desc {
    box-shadow: 2px 4px 30px #0000003b;
    border-radius: 15px;

    .plan-title {
      color: #382564;
      text-align: center;
      font-weight: bold;
      padding-top: 25px;
    }

    .plan-item {
      display: flex;
      color: #382564;
      font-size: 0.85rem;
      padding: 0 15px;
      align-items: center;

      &.plan-fees {
        font-size: 0.75rem;
        padding: 0 7px;
      }

      &.plan-price {
        height: 70px;
        padding: 0 5px;

        .price {
          font-size: 1.6rem;
          font-weight: bold;

          span {
            font-size: 1rem;
          }
        }
      }

      &.plan-item-icon {
        justify-content: center;
        font-size: 1.5rem;
      }
    }

    &.plan-features {
      .plan-item {
        justify-content: center;
      }
    }

    &.plan-free {
      .plan-item {
        i {
          color: #282a31;
        }
      }
    }

    &.plan-basic {
      border: 3px solid #ffeec1;

      .plan-item {
        i {
          color: #f6d068;
        }
      }
    }

    &.plan-advanced {
      border: 3px solid #efc341;

      .plan-item {
        i {
          color: #fcc010;
        }
      }
    }

    &.plan-pro {
      border: 3px solid #ffeec1;

      .plan-item {
        i {
          color: #ed9800;
        }
      }
    }
  }

  .awards-item {
    color: #6212d7;
    font-weight: bold;
    text-align: center;
  }

  .foundation-logos {
    text-align: center;

    img {
      height: 50px;
      margin: 0 10px;
    }
  }

  .media-logos {
    text-align: center;

    img {
      height: 30px;
      margin: 0 20px;
    }
  }

  .awards-item {
    img {
      max-height: 150px;
      max-width: 100%;
      margin-bottom: 25px;
    }
  }

  .tab-content {
    border: 1px solid #6212d7;
    margin: 50px 0 10px;
    padding: 30px 20px;

    .tech-item {
      color: #382564;

      i {
        color: #7827ee;
        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }

  .partners {
    text-align: center;

    img {
      height: 50px;
      margin: 0 10px;
      margin-bottom: 15px;
    }
  }

  .academy-tab-section {
    display: flex;
    justify-content: center;
    align-items: center;

    .academy-tab {
      color: #c1c1c1;
      font-weight: bold;
      margin: 10px 20px;
      display: flex;
      cursor: pointer;

      .tab-btn {
        height: 20px;
        width: 20px;
        background-color: #c1c1c1;
        border-radius: 100%;
        margin-right: 10px;
      }

      &.active {
        color: #7827ee;

        .tab-btn {
          background-color: #7827ee;
        }
      }
    }
  }

  .academy-content {
    color: #382564;
    padding: 0 50px;

    .academy-card {
      box-shadow: 0px 8px 18px #00000029;
      border-radius: 15px;
      padding: 15px 15px;
      margin-bottom: 25px;

      p {
        text-align: left;
      }
    }
  }

  .mobile-item-container {
    display: flex;
  }

  .mobile-icon {
    font-size: 1.2rem;

    &.disabled {
      color: #DADADA;
    }
  }

  .title-mobile {
    display: inline-block;
    width: 110px;
  }

  @media (max-width: 768px) {

    .plan-toggle-buttons {

      text-align: center;
      margin-bottom: 50px;

      button {
        padding: 5px 35px;
        font-size: 0.85rem;
      }
    }

    .academy-tab-section {
      flex-direction: column;
    }

    #title-plans {
      margin-bottom: 65px;
    }

    .bubble {
      left: 50px;
    }
  }

}

.nibi-home {
  .top-banner {
    margin-top: 50px;
    background-image: url("../../img/FoundationLanding/foundations_banner.png");
  }
}