.redeem-nibis-reminder {
  display: flex;
  align-items: center;
  margin: 15px 0;

  img {
    height: 45px;
  }

  .notif-reminder {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: #6212D7;
    margin-right: 5px;
  }

  p {
    color: #302464;
    margin: 0;
    font-size: 0.9rem;

    span {
      color: #6212D7;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}
