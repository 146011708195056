.alert-heading-message {
  font-size: 1rem;
}


.landing-pse-payment-component.recurring-payment-component-benefits {
  margin-top: 0;
  margin-bottom: 0;

  .recurring-payment-component-benefits-title {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;
  }
}

.program-donation-summary {
  padding: 15px 15px 25px;
  color: #302464;

  .title {
    font-weight: bold;
    margin: 0;
  }

  .program-name {
    color: #808080;
    font-weight: 300;
  }

  .row-donation-summary {
    border-bottom: 1px solid rgba($color: #302464, $alpha: 0.6);
    margin-bottom: 10px;

    p {
      margin-bottom: 5px;
    }
  }

  .program-donation-payment-methods {

    margin-bottom: 10px;

    .custom-control-input:checked~.custom-control-label::before {
      background-color: #6212D7;
      border-color: #6212D7;
    }

    .custom-control-input:disabled~.custom-control-label {
      i {
        color: #6C757D;
      }
    }

    i {
      color: #6212D7;
    }

    p {
      text-align: center;
    }
  }

  .program-donation-add-to-cart {
    color: #6212D7;
    font-size: 1.2rem;
    text-align: center;

    span {
      cursor: pointer;
    }

    &.added-to-cart {

      span {
        cursor: default;
      }

      i {
        color: #D3D3D3;
      }
    }
  }

}