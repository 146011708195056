.container-primary-navbar-mobile {
  margin-bottom: 1.9rem;
}

.navbar {
  position: sticky;
  top: 0;
  text-align: center;
  transition: 0.4s;
  background: linear-gradient(81.64deg, #ffd836 34.46%, #f6ac0d 87.54%);
  font-family: "Montserrat", sans-serif;
  height: 60px;
  width: 100%;
  z-index: 150;
  scroll-behavior: auto;

  &.hiddennavbar {
    background: transparent;
    display: none;

    .navbarLeftSection {
      display: none;
    }
  }

  .navbarCenterSection {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    max-height: 100px;

    .mr-auto {
      justify-content: center;
    }
  }

  .navbarLeftSection {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-height: 100px;

    .mr-auto {
      justify-content: center;
    }
  }

  .alt-navbar {
    justify-content: space-between;
    border-bottom: #302464 1px solid;

    a {
      padding-bottom: 5px;
      line-height: 1;
      height: fit-content;
    }
  }

  .userFunctionsSection {
    height: 80px;
    line-height: 80px;
    display: flex;
    width: 25%;
    align-items: flex-end;
    text-align: right;
    justify-content: flex-end;

    .functionIcon {
      padding: 0 12px;
      font-size: 16px;
      cursor: pointer;
      color: #6212d7;
      font-weight: 600;

      &:hover {
        color: #fff;
      }

      &.avatar {
        padding: 0;
        height: 50px;
        width: 50px;
        background-repeat: no-repeat;
        border-radius: 100%;
        background-size: cover;
        background-position: center;
        margin-bottom: 16px;
      }

      .dropdown-toggle:empty::after {
        position: relative;
        bottom: -9px;
        left: -14px;
        font-size: 1.3em;
      }
    }

    a,
    button {
      color: #302464;
      font-weight: 500;
      padding: 6px;

      &.logButton {
        padding: 6px;
        margin-right: 8px;
        background-color: #fff;
        text-align: center;
        color: #6212d7;
        border: #6212d7 solid 1px;
        border-radius: 20px;
        width: 45%;
        font-size: 12px;
        margin-top: 6px;
      }
    }

    .dropdownTitle {
      padding-left: 6px;
      text-align: left;
      font-size: 20px;
      color: #302464;
      font-weight: 600;
    }

    .dropdownSubtitle {
      margin-top: 3px;
      padding-left: 6px;
      text-align: left;
      font-size: 12px;
      color: #302464;
      font-weight: 600;
    }
  }

  .emergency-web-button-navbar .salvemos-2020-btn.hexagon-btn {
    background-color: #6212d7;

    &::after {
      border-left: 12px #6212d7 solid;
    }

    &::before {
      border-right: 12px #6212d7 solid;
    }
  }

  .chat-notification-bubble {
    position: absolute;
    top: 15px;
    height: 12px;
    width: 12px;
    background-color: #6212d7;
    font-size: 0.7rem;
    font-weight: 300;
    border-radius: 100%;
  }

  .toggle-mobile.dropdown.nav-item {
    a.dropdown-toggle.nav-link::after {
      display: none;
    }
  }

  .mobile-nibis-coin {
    position: absolute;
    bottom: -35px;
    right: 5px;
  }

  .navbar-mobile-tab-container {
    display: block;
    position: relative;

    .mobile-active-tab {
      position: absolute;
      bottom: -15px;
      left: -3px;
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .highlight-link-span {
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem 5px;
    line-height: 1;
    height: fit-content;
  }

  a {
    font-weight: 600;
    font-size: 13px;
    text-decoration: none;
    color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem 5px;

    &.highlight-link-2 {
      color: #fff;
      padding: 0px 10px;
      cursor: pointer;

      &:hover {
        color: #312067;
      }
    }

    &.highlight-link {
      border-bottom: 3px solid transparent;
      max-height: 40px;

      &:hover {
        border-bottom: 3px solid #312067;
      }
    }
  }

  &.navbar-dark {
    .navbar-toggler {
      margin: 1% 0px;
      border-color: #fff;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#FFF' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    &.navbar-toggler:focus {
      outline: none;
    }
  }

  .nav-dropdown-item {
    color: #000;
    text-align: left;
    line-height: 24px;
  }

  .emergency-web-button-navbar {
    .hexagon-btn {
      color: #fff;
      background-color: #ff5757;
      font-weight: 600;
      font-size: 14px;
      height: 22px;
      width: fit-content;
      padding: 0 6px;

      &::before {
        border-right: 12px #ff5757 solid;
      }

      &::after {
        border-left: 12px #ff5757 solid;
      }
    }

    .hexagon-btn:after,
    .hexagon-btn:before {
      position: absolute;
      content: "";
      z-index: 1;
      top: 0;
      width: 0px;
      background: transparent;
      border-top: 11px transparent solid;
      border-bottom: 11px transparent solid;
    }
  }

  .register-web-button-navbar {
    .hexagon-btn {
      line-height: normal;
      color: #fff;
      background-color: #302465;
      font-weight: 600;
      font-size: 14px;
      width: fit-content;
      padding: 0 15px;

      &::before {
        border-right: 12px #302465 solid;
      }

      &::after {
        border-left: 12px #302465 solid;
      }
    }
  }

  .registerBtnMobile {
    border: none;
    border-radius: 30px;
    font-size: 11px;
    background-color: #6212d7;
    color: #fff;
    padding: 2px 6px;
  }
}

.navbar-brand {
  position: relative;
  width: 25%;
  height: 80%;
  margin-left: auto;
  margin-right: auto;

  #homeLogo {
    position: absolute;
    height: 100%;
    top: 0;
    left: 25%;
  }
}

#exploraBtn {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border: none;
  background: rgb(41, 0, 68);
  background: linear-gradient(85deg,
      rgba(41, 0, 68, 0.7931547619047619) 21%,
      rgba(153, 102, 204, 0.7343312324929971) 100%);
}

#emergencyBannerNav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 35px;
  margin-left: 0;
  background-color: #ff5757;
  color: #fff;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  padding-left: 5px;
  padding-right: 5px;
}

.nibisWindowContent {
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatNotification {
  margin-right: -5px;
  margin-left: -5px;
  color: red;
  font-size: 8px;
}

.search-bar {
  padding-left: 50px;
  margin-top: 12px;
  width: 400px;
  font-size: 13px;

  .fa-search {
    margin-right: 8px;
  }
}

.search-bar-mobile {
  margin-top: 12px;
  width: 400px;
  font-size: 13px;

  .fa-search {
    margin-right: 8px;
  }
}

.dropdown-menu.show {
  padding: 16px;
  width: 70vw;
  height: auto;
}

// modificatios for mobile
@media (max-width: 920px) {
  .navbar {
    height: 90px;
    padding: 8px 0px 0px 0px;

    .search-bar {
      margin-top: 0px;
    }

    .navbarCenterSection {
      position: absolute;
      bottom: -1.4rem;
      display: flex;
      align-items: center;
      justify-self: center;
      width: 100%;
      // background-color: red;
      overflow: visible;

      .form-inline {
        width: 100%;

        .search-bar-mobile {
          width: 80%;
          margin-left: auto;
          margin-right: auto;

          .search-bar-input {
            .css-1mts17z-control {
              height: 45px;
            }

            .css-gabtet-control {
              height: 45px;
            }
          }
        }
      }
    }

    .userFunctionsSection {
      position: absolute;
      bottom: 1.7rem;
      right: 0;
      width: auto;
      height: 40px;
      line-height: 20px;

      .toggle-mobile {
        img {
          margin-right: 1rem;
        }
      }
    }

    .navbar-brand {
      position: absolute;
      top: 0.7rem;
      width: 100%;
      max-height: 47px;
      padding: 0;

      a {
        padding: 0;

        #homeLogo {
          position: relative;
          max-width: 100%;
          height: 37px;
          padding: 0;
          left: 0;
        }
      }
    }
  }
}

// ====================================================
@media (max-width: 768px) {
  .navbar {
    height: 90px;
    padding: 8px 0px 0px 0px;

    .search-bar {
      margin-top: 0px;
    }

    .navbarCenterSection {
      overflow: visible;
    }

    .userFunctionsSection {
      height: 40px;
      line-height: 20px;

      .dropdown-menu.show {
        padding: 16px;
        width: 100vw !important;
        height: 90vh;
      }

      .emergency-web-button-navbar {
        margin-top: 8px;
        margin-bottom: 8px;
        text-align: center;
      }

      .toggle-mobile {
        line-height: 27px;
        width: 100%;

        img {
          margin-right: 10px;
          height: 20px;
        }
      }

      #shoppingCartIcon {
        font-size: 14px;

        .nav-link {
          padding-right: 0;
          line-height: normal;
        }
      }
    }

    .navbar-brand {
      #homeLogo {
        left: 15%;
        height: 30px;
      }
    }
  }

  .navbar-header {
    top: 5px;
  }

  .highlight-link {
    font-size: 0.85em;
  }
}

.business-navbar-logo {
  img {
    height: 50px;
    margin-left: 150px;
  }
}

.general-notification {
  font: normal normal 800 18px/24px Montserrat;
  background-color: #f79009;
  color: #ffffff;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 8px;
}