.fb-login-btn.hexagon-btn {
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition: background-color .3s ,border-color .3s;

  i {
    margin-right: 10px;
    color: #4267B2;
  }

  &:hover {
    opacity: 0.9;
  }
}

.google-login-btn.hexagon-btn, .google-login-btn.hexagon-btn:disabled {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 0.9;
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.6;
  }

  .logo {
    margin-right: 10px;
    border-radius: 2px;
  }

  .text {
    padding: 10px 10px 10px 0px;
    font-weight: 500;
  }
}


.redirectLoginLink {
  color: dodgerblue;
}

.redirectLoginLink:hover {
  text-decoration: underline;
  cursor: pointer;
}