.email-validation-warning-component {
  p {
    span {
      color: #302465;
      cursor: pointer;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.email-validation-toast {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 100;
}
