.pse-payment-component {
  .text-invalid {
    display: block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #DC3545;
  }

  .was-validated {
    .form-control.invalid-value {
      border-color: #DC3545;
    }
  }

  .form-control.is-valid, .was-validated .form-control:valid {
    background-image: none;
  }
}

.pse-transaction-spinner {
  .message {
    color: #312067;
    text-align: center;
  }
}
