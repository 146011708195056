.chat-push-notifications-component {
  position: fixed;
  right: 10px;
  top: 110px;
  z-index: 0;
  height: fit-content;

  .notificationsIcon {
    cursor: pointer;
    text-align: right;
    margin-right: 20px;
    margin-bottom: 20px;

    i {
      font-size: 2em;
      color: #6212D7;
    }
  }

  .chat-push-notification {
    cursor: pointer;
    max-width: 35vw;

    p {
      font-size: 0.75rem;
    }

    .chat-push-notification-info {
      display: flex;
      align-items: center;
      max-width: 500px;

      .push-notification-icon {
        font-size: 30px;
        padding: 10px;
        color: rgb(98, 18, 215);
        opacity: 0.7;
      }

      .new-notification {
        border-radius: 100%;
        background-color: #6212D7;
        height: 10px;
        width: 10px;
        margin-right: 10px;      
      }

      img {
        border-radius: 100%;
        height: 50px;
        width: 50px;
      }

      p {
        margin: 0;
        margin-left: 10px;

        &.notification-header {
          color: #000;
        }
  
        &.sender-name {
          color: rgb(98, 18, 215);

          .black-text {
            color: #000
          }

          .notification-link:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          a {
            color: rgb(98, 18, 215);
          }

        }
      }
    }

    .notification-message-text {
      margin: 5px 10px;
      overflow-y: hidden;

      p {
        margin: 0;
      }

      a {
        color: #6212d7;
        font-size: 0.9rem;
      }

    }
  }
}
