.subscription-payment-landing-component {
  margin-top: 100px;
  margin-bottom: 50px;

  .btn {
    width: fit-content;
    padding-right: 50px;
    padding-left: 50px;
  }

  color: #312067;

  h2 {
    text-align: center;
  }

  .title {
    margin-bottom: 20px;
  }

  .message {
    margin-bottom: 40px;
  }

  a, a:hover {
    text-decoration: none;
  }

  .btn.btn-modal {
    width: fit-content;
    font-size: 1.2rem;
  }
}

.landing-pse-spinner {
  .message {
    color: #312067;
    text-align: center;
  }
}
