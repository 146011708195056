.achievement-wrapper {
  cursor: pointer;
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .achievement-super {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    .achievement-body {
      position: relative;
      -webkit-transform: translateY(64px);
      transform: translateY(64px);
      margin: 0 auto;
      width: 75px;
      overflow: hidden;
      height: 75px;
      line-height: 20px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      border-radius: 45px;
      background-color: #6212D7;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.2) 40%,
        rgba(255, 255, 255, 0.2) 60%,
        rgba(255, 255, 255, 0) 100%
      );
      background-repeat: no-repeat;
      background-size: 50% 100%;
      background-position: -100%;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    }
    .achievement-body .achievement-text {
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      opacity: 0;
      margin: 0;
    }
    .achievement-body .achievement-subtext {
      color: #f5f5f5;
      font-size: 14px;
      font-weight: 300;
      opacity: 0;
      margin: 0;
    }
    .achievement-icon{
      background: rgba(255, 255, 255, 0);
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 64px;
      margin: 0 auto;
      box-sizing: border-box;
      opacity: 0;
      img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: relative;
        left: -140px;
        top: -7px;
      }
    }
  }
}

.achievement-wrapper.animation {
  animation: slideDown 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 0s 1 forwards;
  -webkit-animation: slideDown 750ms cubic-bezier(0.75, -0.5, 0, 1.75) 0s 1 forwards;
}

.achievement-wrapper.animationExit {
  animation: slideUp 1000ms cubic-bezier(0.75, -0.5, 0, 1.75) 0ms 1 forwards;
  -webkit-animation: slideUp 1000ms cubic-bezier(0.75, -0.5, 0, 1.75) 0ms 1 forwards;
}

.achievement-wrapper.animation .achievement-body {
  animation: expand 750ms ease 650ms 1 forwards;
  -webkit-animation: expand 750ms ease 650ms 1 forwards;
}

.achievement-wrapper.animationExit .achievement-body {
  animation: contract 400ms ease 0ms 1 forwards, shimmer 1500ms linear 0ms 2;
  -webkit-animation: contract 400ms ease 0ms 1 forwards, shimmer 1500ms linear 0ms 2;
}

.achievement-wrapper.animation .achievement-text {
  animation: fadeInUp 550ms ease 1250ms 1 forwards;
  -webkit-animation: fadeInUp 550ms ease 1250ms 1 forwards;
}

.achievement-wrapper.animationExit .achievement-text {
  animation: fadeOutUp 100ms ease 0ms 1 forwards;
  -webkit-animation: fadeOutUp 100ms ease 0ms 1 forwards;
}

.achievement-wrapper.animation .achievement-subtext {
  animation: fadeInUp 550ms ease 1350ms 1 forwards;
  -webkit-animation: fadeInUp 550ms ease 1350ms 1 forwards;
}

.achievement-wrapper.animationExit .achievement-subtext {
  animation: fadeOutUp 100ms ease 0ms 1 forwards;
  -webkit-animation: fadeOutUp 100ms ease 0ms 1 forwards;
}

.achievement-wrapper.animation .achievement-icon {
  animation: fadeInUp 0ms ease 1250ms 1 forwards;
  -webkit-animation: fadeInUp 550ms ease 1250ms 1 forwards;
}

.achievement-wrapper.animationExit .achievement-icon {
  animation: fadeOutUp 100ms ease 0ms 1 forwards;
  -webkit-animation: fadeOutUp 100ms ease 0ms 1 forwards;
}

@-webkit-keyframes slideUp {
  0% {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
  }
  100% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
  }
  100% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@-webkit-keyframes slideDown {
  100% {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
  }
  0% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@keyframes slideDown {
  100% {
    transform: translateY(100px);
    -webkit-transform: translateY(100px);
  }
  0% {
    transform: translateY(-135px);
    -webkit-transform: translateY(-135px);
  }
}

@-webkit-keyframes expand {
  0% { width: 64px; }
  100% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@keyframes expand {
  0% { width: 64px; }
  100% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@-webkit-keyframes contract {
  100% { width: 64px; }
  0% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@keyframes contract {
  100% { width: 64px; }
  0% {
    width: 96vw;
    padding: 8px 40px;
  }
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@-webkit-keyframes ripple {
  100% { box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08); }
  0% { box-shadow: inset 0 0 5px 50px rgba(0, 0, 0, 0); }
}

@keyframes ripple {
  100% { box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.08); }
  0% { box-shadow: inset 0 0 5px 50px rgba(0, 0, 0, 0); }
}

@-webkit-keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

@keyframes shimmer {
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
}

@media only screen and (min-width: 480px) {

  @-webkit-keyframes expand {
    0% { width: 64px; }
    100% {
      width: 364px;
      padding: 8px 40px;
    }
  }

  @keyframes expand {
    0% { width: 64px; }
    100% {
      width: 364px;
      padding: 8px 40px;
    }
  }

  @-webkit-keyframes contract {
    100% { width: 64px; }
    0% {
      width: 364px;
      padding: 8px 40px;
    }
  }

  @keyframes contract {
    100% { width: 64px; }
    0% {
      width: 364px;
      padding: 8px 40px;
    }
  }
}