.shoppingCartSection{
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.itemImgContainer{
  width: 100%;
  height: 90px;
  overflow: hidden;
}

.itemImg{
  width: 100%;
}

.itemName{
  font-weight: bold;
}

.itemPrice{
  text-align: right;
}

.dateAdded{
  font-size: 0.7em;
  color: darkgray;
}

.addressOption{
  border-style: solid;
  border-color: darkgray;
  border-width: thin;
  border-radius: 5px;
}

.addressText{
  font-size: 0.75em;
}

.deleteAddress{
  float: right;
  padding-top: 5px;
}

.summaryContainer{
  padding: 12px;
  padding-top: 16px;
  background-color: #F3F3F3;
  border-style: solid;
  border-color: lightgray;
  border-width: thin;
  border-radius: 5px;
}

.chooseAddressTitle{
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 8px;
}

.summaryAddressInfo{
  font-size: 12px;
  margin-top: 6px;
}

.nibiAlertTitle{
  margin-bottom: 16px;
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}