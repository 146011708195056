.nibify-foundation-card {
  background-color: none;
  filter: drop-shadow(4px 5px 15px #00000029);
  border-radius: 16px;

  .card-imgs {
    display: flex;
    justify-content: space-between;
    background-size: cover;
    padding: 10px;
    padding-bottom: 50px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .ods {
      margin-bottom: 10px;

      img {
        border-radius: 100%;
        height: 30px;
      }
    }
    
    .foundation-logo {
      border-radius: 100%;
      background-size: cover;
      background-color: #FFF;
      background-repeat: no-repeat;
      background-position: center;
      height: 100px;
      width: 100px;
    }
  }

  .foundation-info {
    min-height: 260px;
    background-color: #FFF;
    margin-top: -15px;
    border-radius: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0 10px 10px 10px;

    .card-btn {
      font-family: 'Poppins', sans-serif;
      border-radius: 20px;
      padding: 10px 25px;
      height: 46px;
      background-color: #282A31;
      border: 1px solid #7827EE;
      color: #FFF;
      font-weight: bold;
      position: relative;
      top: -23px;
      display: block;
      margin: 0 auto;
    }
    
    .info {
      .foundation-name {
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        color: #7827EE;
        font-weight: bold;
        margin-top: -10px;
      }

      .foundation-desc {
        font-family: 'Montserrat', sans-serif;
        color: #072450;
        font-size: 0.9rem;
      }
    }

  }
}
