.payment-extension-component-fp {
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 100px;

  .title {
    font-weight: 800;
    color: #E22385;
    letter-spacing: 0.15em;
  }

  .plan-description {
    margin-bottom: 50px;

    .plan-title {
      font-weight: 700;
      color: #444444;
    }

    p {
      color: #444444;
      font-weight: 400;
    }
  }

}
