.media-logos {
  text-align: center;
  img {
    height: 30px;
    margin: 0 20px;
    margin-bottom: 15px;
  }
}

.awards-item {
  color: #6212d7;
  font-weight: bold;
  text-align: center;
  img {
    max-height: 150px;
    max-width: 100%;
    margin-bottom: 25px;
  }
}

.foundation-logos {
  text-align: center;
  img {
    height: 50px;
    margin: 0 10px;
  }
}

.partners {
  text-align: center;
  padding-top: 2%;
  img {
    height: 50px;
    margin: 0 10px;
  }
}
