.nibify-payment-component {
  h5 {
    color: #34206F;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
  }

  label.form-label {
    color: #9A9A9A;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
  }

  .payment-button {
    font-family: 'Poppins', sans-serif;
    border: 1px solid #7827EE;
    border-radius: 7px;
    height: 40px;
    background-color: #FFF;
    color: #7827EE;
    font-weight: bold;
    width: 200px;
    display: block;
    margin: 50px auto 0;
  }

  .custom-control-label {
    color: #646464;
    font-size: 0.9rem;
  }
}
