.btn-modal {
  width: 50%;
  display: block;
  margin: 10px auto;

  background-color: #FFF;
  border-color: #312067;
  color: #312067;

  &:hover {
    background-color: #312067;
    border-color: #312067;
    color: #FFF;
  }
}

.modal-button.hexagon-btn {
  width: fit-content;
  padding: 0 30px;
  font-weight: 500;
}

.register-login-link {
  background-color: #F2E8DD;
  width: 100%;
  height: 50px;
  color: rgba(35, 34, 41, 0.8);
  font-size: 0.9rem;
  line-height: 46px;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;

  span {
    font-weight: bold;
    color: #302465;
  }
}

.userTypeBtn {
  transition: 0.3s all;
  border-radius: 10px;
  padding: 15px 5px;
  // margin: 0 5px;

  h6 {
    color: #312067;
  }

  &.selected {
    background-color: rgba(242, 198, 1, 0.4);

    h6 {
      font-size: 1.3rem;
    }
  }

  &:hover {

    &:not(.selected) {
      background-color: lightgrey;
    }

    cursor: pointer;

  }
}

form button.btn.btn-modal:not(:disabled):not(.disabled):active {
  background-color: #312067;
  border-color: #312067;
  color: #F2C600;
}

.modal {
  &.fade {
    .modal-footer {
      justify-content: flex-start;

      a {
        color: #312067;
      }
    }
  }
}

.modalLabel {
  font-size: 0.7em;
  font-weight: bold;
}

#duplicateUser, .signup-error, .incorrectData {
  width: 100%;
  background-color: #FFBABA;
  color: #D8000C;
  text-align: center;
  font-size: 0.7em;
  padding: 5px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.signup-separador {

  display: flex;
  align-items: center;

  .line {
    height: 1px;
    width: 47%;
    background-color: #E4E4E4;
  }

  .text {
    width: 6%;
    text-align: center;
    color: #E4E4E4;
  }
}

.login-btn-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .privacy-terms {
    font-size: 12px;
    color: #747184;

    .forgot-password {
      font-size: 12px;
    }
  }
}

.register-modal-back-btn {
  margin-right: 10px;
  font-size: 1.3rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .register-option {
    font-size: 0.8em;
    h6{
      font-size: 1em;
    }
    img{
      height: 50px;
    }
  }
  .login-btn-section {

    display: block;
    text-align: center;

    span {
      width: 100%;
      margin-bottom: 15px;
      display: block;
    }
  }

  .google-fb-signup {

    .hexagon-btn.google-login-btn, .fb-login-btn.hexagon-btn,
    .hexagon-btn.google-login-btn:disabled, .fb-login-btn.hexagon-btn:disabled {

      .logo {
        width: fit-content;
        display: inline-block;
      }

      width: 85%;
      margin-bottom: 15px;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}

.google-fb-signup {
  margin: 1rem 0;
}

a.forgot-password {
  margin-top: 5px;
  color: #302465;
  font-size: 0.9rem;
  font-weight: 600;

}

.modal-title {
  strong {
    color: #312067;
  }
}

.paymentTypeCard {
  cursor: pointer;
  transition: 0.3s all;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.2), 0 2px 5px 0 rgba(0,0,0,0.2);
  margin: 10px;
  padding: 10px;
}

.paymentTypeCard:hover {
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.5), 0 2px 5px 0 rgba(0,0,0,0.5);
}


.payment-footer {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20%;
  }

  p {
    margin: 0;
    margin-right: 20px;
    font-size: 1.5rem;
    color: #009EE3;
  }
}

.register-options-modal {
  background-color: #F2E8DD;
}

.register-options-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.register-options-modal-body {
  
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;

  .register-option {
    display: flex;
    padding: 20px 24px;
    background-color: #FFF;
    margin-bottom: 12px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      h6 {
        text-decoration: underline;
      }
    }

    h6 {
      font-weight: 600;
      color: #302465;
      margin-bottom: 4px;
      line-height: 1.33;

      i {
        font-size: 13px;
      }
    }

    p {
      line-height: 1.3;
      color: #232229;
      font-weight: 300;
      margin: 0;
    }

    img {
      margin-right: 24px;
    }
  }

  .register-business{
    line-height: 1.3;
    color: rgba(35, 34, 41, 0.8);
    font-size: 14px;
    margin: 24px 0 8px;

    p {
      text-align: center;
      margin: 0;

      span {
        color: #302465;
        font-weight: 600;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    
  }

}
