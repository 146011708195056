.programCardHome {
  font-family: 'Barlow', sans-serif;
  background-color: #FFF;
  border-radius: 20px;
  box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.2), 0 2px 5px 0 rgba(0,0,0,0.2);
  overflow: hidden;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  max-width: 360px;
  height: 574px;

  .program-card-business-logo {
    position: absolute;
    background: transparent;
    border-radius: 100%;
    width: 32px;
    top: 16px;
    left: 32px;
  }

  .programCardFoundationName{
    color: #302465;
    font-size: 13px;
    height: 20px;
    overflow: hidden;
    margin-bottom: 4px;
    small{
      font-size: 13px;
      color: #302465;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.39px;
    }
  }
  
  .programCardProgramName{
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #302465;
    height: 48px;
    overflow: hidden;
    margin-bottom: 8px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }
  
  .programCardProgramDescription{
    line-height: 16px;
    font-size: 13px;
    color: #302465;
    height: 32px;
    overflow: hidden;
    margin-bottom: 8px;
  }
  
  .raisedInfo{
    font-size: 12px;
    font-weight: 600;
    color: #302465;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  
  .donationInfo{
    font-size: 11px;
    margin-bottom: 2px;
    color: #232229;
  }
  
  .programCardDetails{
      font-size: 13px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.45px;
      color: #302465;
      text-align: left;
      margin-bottom: 8px;
      i{
        font-size: 12px;
      }
  }
  
  .programCardImgHome {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
    height: 250px;
  }
  
  .programOds{
    position: absolute;
    right: 15px;
    top: 16px;
  }
  
  .programEmergencyTag { 
    position: absolute;
    top: 200px;
    right: 20px;
    width: 70px;
    height: 28px;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.42px;
    background-color: #fc5252;
    text-decoration:none;
    color: #FFF;
    text-align:center;
    border: none;
    padding-top: 7px;
  }
  
  .programEmergencyTag:after,
  .programEmergencyTag:before {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;  
    width: 0px;
    background: transparent;
    border-top: 14px transparent solid; 
    border-bottom: 14px transparent solid;
    
  }
  
  .programEmergencyTag:before {
      left: -10px;
      border-right: 10px #fc5252 solid; 
  }
  .programEmergencyTag:after {
      right: -10px;
      border-left: 10px #fc5252 solid; 
  }
  
  .programOdsImg{
    height: 32px;
    border-radius: 100%;
    background-color: #FFF;
  }
  
  .programCardContent {
    padding: 15px;
  
    .program-card-content-logo img {
      border-radius: 100%;
    }
  }

  .programCardRatingTag{
    position: absolute;
    top: 188px;
    left: 15px;
    font-family: 'Barlow', sans-serif;
    font-size: 32px;
    color: #FCC010;
    text-align:center;
    span{
      font-size: 12px;
      color: #FFF;
      position: absolute;
      top: 42%;
      left: 27%;
      font-weight: 500;
    }
    .noRating{
      color: #ddd;
    }
  }

  .toProgramBtn{
    background-color: #6212D7;
    border-radius: 14px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    border: none;
    font-weight: 500;
    width: 120px;
    height: 36px;
    margin-top: 6px;
  }

  .donationsProgressBar .progress-bar{
    background-color: #FCC010;
    height: 30px; 
    border-radius: 50px; 
    margin-bottom: 8px;
  }
  
  .progress{
    border-style: solid;
    border-width: 1px;
    border-color: #ffd75e;
    height: 30px; 
    border-radius: 50px; 
    margin-bottom: 16px;
  }
}
