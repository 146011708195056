.footer {
  background-color: rgba(48, 36, 101, 1);
  font-size: 15px;
  padding-bottom: 20px;
  letter-spacing: 0.53px;
  text-align: left;

  i {
    font-size: 15px;
    margin-right: 12px;
    width: 20px;
  }

  li {
    font-size: 16px;
    margin-bottom: 10px;
    z-index: 2;
  }

  .social-icons {
    margin: 0 auto;
    div{
      margin-bottom: 10px;
    }
  }

  a {
    color: #F59D19;
    margin-bottom: 10px;

    &:hover {
      color: rgb(242, 198, 0);
      text-decoration: none;
    }
  }

  .footer-img {
    width: 85px;
    display: block;
    color: #FFF;
  }

  .copyright {
    font-size: 11px;
    text-align: center;
    margin: 0;
    margin-top: 20px;
    opacity: 0.3;
    color: #FFF;
  }


  .footer-text {
    opacity: 0.3;
    color: #FFF;
  }
}